import React from 'react';
import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import SafeLink from '../components/SafeLink';

const ProjectSynergy = () => {

  return(
    <>
      <Page title='Project Synergy'>
        <Section>
          <Typography type='hero' preset='1'>Project Synergy</Typography>
          <Typography type='paragraph' preset={2}>Supporting people's mental health with digital technology</Typography>
         
          <Typography type='heading' preset='1'>Overview</Typography>
            <Typography type='paragraph' preset={2}>Project Synergy was a government-funded series of research trials that are designed to measure the effectiveness of the Synergy Online System - a technology Platform to deliver mental health care.</Typography>            
            <Typography type='paragraph' preset={2}>It covered the entire lifespan - including younger children and their families, young people, adults and the veteran community, healthy ageing - and is designed to measure the impact of the Platform in Australian mental health services. The research trials was funded under the Australian government's Digital Mental Health Program. Project Synergy brought together individuals with lived experience of mental ill-health, clinicians, service providers, researchers and technologists to achieve its aims.</Typography>
            <Typography type='paragraph' preset={2}>Innowell was established to deliver Project Synergy and explore sustainable business models to support mental health service system reform.</Typography>
            <Typography type='paragraph' preset={2}>The research based on the Synergy Online System has resulted in the Innowell Platform - an innovative, well-tested and validated world-class digital mental health platform.</Typography>
        
            <Typography type='heading' preset='1'>Research &amp; Development</Typography>
            <Typography type='paragraph' preset={2}>Innowell procured the research services of The University of Sydney's <SafeLink to="https://www.sydney.edu.au/brain-mind/">Brain and Mind Centre</SafeLink> to provide an independent Research and Development Group (R&D Group) and ensure the ethical integrity of the trials. The R&amp;D Group led the Project Synergy trials in collaboration with a wide range of mental health service providers.</Typography>
            <Typography type='paragraph' preset={2}>In collaboration with individuals, supportive others, health professionals and service providers, the R&amp;D Group tested the design, usability and functionality of the Innowell Platform to ensure it was suited to best meet the needs of specific health providers and the individuals who use their services.</Typography>
            <Typography type='paragraph' preset={2}>Based on the research trials, the resultant Platform was then implemented within specific health services and studied in a real-world context. The study aimed to evaluate the impact of the Platform on individual user health and social outcomes, and changes in service metrics (such as reduced wait times, improved access, and improved patient outcomes). The results of these real-world trials helped to further improve the Innowell Platform.</Typography>

            <Typography type='heading' preset='1'>Co-Design and Lived Experience</Typography>
            <Typography type='paragraph' preset={2}>The Innowell Platform used Participatory / Co-Design approaches to incorporate elements of what users of such systems find useful and valuable.</Typography>
            <Typography type='paragraph' preset={2}>This included aspects of accessibility, language, utility and aesthetics, with broad consideration for what might be desirable while also balancing the technological and operational feasibility of such features. The Co-Design took input from, and balanced a range of voices from individuals with Lived Experience, their carers, Mental Health Professionals, Health Service Provider Organisations and Digital technologists.</Typography>
            <Typography type='paragraph' preset={2}>To find out more, download our supplement <a href="/reports/project-synergy/ch2-research-and-development-cycle.pdf" target="_blank" rel="noopener">Project Synergy: Research and development cycle</a> from the peer-reviewed Medical Journal of Australia.</Typography>
            <Typography type='paragraph' preset={2}>Project Synergy included a Lived Experience Advisory Function (LEAF) to ensure that the voices of people with a lived experience were considered and suitably incorporated in the Platform. Lived experience refers to the knowledge and insights of people who have a personal experience of mental ill health, and it deeply informed and guided the design of our mental health research, product development and community engagements.</Typography>
            <Typography type='paragraph' preset={2}>LEAF was made up of two complementary groups of diverse people across Australia:</Typography>
            <ul>
                <li><Typography type='paragraph' preset={2}>The LEAF Working Group: group of community leaders who met monthly to co-design and lead Innowell's National Community Consultation activities</Typography></li>
                <li><Typography type='paragraph' preset={2}>The LEAF Panel: a broad and diverse reference group who contributed their expertise to Innowell's projects</Typography></li>
            </ul>            
            <Typography type='paragraph' preset={2}>All members of the LEAF represented lived experience, and did not represent the views of any specific organisation or political group.</Typography>
            <Typography type='paragraph' preset={2}>A core LEAF activity was to design and lead the National Community Consultation. The National Community Consultation was created to engage with real people across diverse communities in Australia to understand how digital solutions might improve mental health outcomes. The learnings from these engagements then supported the development of the Innowell Platform and the Project Synergy Trials to make sure it effectively addresses the different needs of all people.</Typography>
            <Typography type='paragraph' preset={2}>Download the <a href="/reports/project-synergy/nccp-june2020-final.pdf" target="_blank" rel="noopener">Final National Community Consultation Report</a>.</Typography>

            <Typography type='heading' preset='2'>Remembering Jackie Crowe</Typography>
            <Typography type='paragraph' preset={2}>Jackie Crowe (1968 - 2017) was an exceptional human and a passionate advocate for mental health. A champion for people with lived experience, Jackie was the Founding Chair of the Lived Experience Advisory Board.</Typography>
        </Section>
        <Section>
            <Typography type='heading' preset='1'>Research Trials</Typography>

            <Typography type='heading' preset='2'>Open Arms - Veterans and Families Counselling</Typography>
            <Typography type='paragraph' preset='2'>Concluded December 2020</Typography>
            <Typography type='paragraph' preset={2}>Trials 5 and 9 of Project Synergy were conducted in partnership with <SafeLink to="https://www.openarms.gov.au/">Open Arms</SafeLink> - Veterans and Families Counselling service, with the Open Arms Sydney and Lismore Centres serving as the research sites. Open Arms is an Australia-wide service that provides counselling and support to current and ex-serving Australia Defence Force personnel, and their family members for mental health conditions (such as post-traumatic stress disorder, anxiety and depression) as well as relationship and family counselling.</Typography>
            <Typography type='paragraph' preset={2}>The primary aim of this research was to co-design, develop, implement and evaluate the Innowell Platform to achieve improved outcomes for current and ex-serving personnel and their families as well as the Open Arms health professionals and service staff.</Typography>

                <Typography type='heading' preset='3'>Publications:</Typography>
                <ul>
                  <li>
                    <Typography type='paragraph' preset={2}>LaMonica HM, Davenport T, Cross S, Burns J, Hodson S, Veitch J, Hickie I. Using participatory design to engage the veteran community in technology-enabled mental health service reform for Open Arms - Veterans and Families Counselling. Journal of Medical Internet Research-Formative Research 2019; 3(3):e13662. <SafeLink to="http://doi.org/10.2196/13662">Technology-Enabled Mental Health Service Reform for Open Arms – Veterans and Families Counselling: Participatory Design Study</SafeLink></Typography>
                  </li>
                </ul>
                <Typography type='heading' preset='3'>Conferences:</Typography>
                <ul>
                  <li>
                    <Typography type='paragraph' preset={2}>LaMonica HM, Davenport TA, Hickie IB. Co-designing technology-enabled solutions for mental health service reform for Open Arms - Veterans and Families Counselling. Oral presentation at the annual meeting of the Society for Mental Health Research, November 2019: Melbourne, Australia.</Typography>
                  </li>
                </ul>

                <Typography type='heading' preset='2'>North Coast NSW - headspace centres</Typography>
                <Typography type='paragraph' preset='2'>Concluded December 2020</Typography>
                <Typography type='paragraph' preset={2}>Trial 6 of Project Synergy was conducted with five <SafeLink to="https://headspace.org.au/">headspace</SafeLink> centres located on the North Coast of NSW. The <SafeLink to="https://hnc.org.au/">North Coast Primary Health Network (NCPHN)</SafeLink> commissions three lead agencies to deliver services from five headspace centres in its footprint:</Typography>
                <ul>
                    <li><Typography type='paragraph' preset={2}>Coffs Harbour and Grafton (GenHealth)</Typography></li>
                    <li><Typography type='paragraph' preset={2}>Lismore and Tweed Heads (Social Futures)</Typography></li>
                    <li><Typography type='paragraph' preset={2}>Port Macquarie (EACH)</Typography></li>
                </ul>
                <Typography type='paragraph' preset={2}>These centres are entry points for youth to access mental health services based on their identified clinical symptoms and risk factors. The primary aim of this research was to enhance the quality and timeliness of mental health services for youth on the North Coast of NSW through technology-enabled solutions implemented within these headspace centres.</Typography>                

                <Typography type='heading' preset='3'>Publications:</Typography>
                <ul>
                  <li>
                    <Typography type='paragraph' preset={2}>Rowe SC, Davenport TA, Easton MA, Jackson T, Melsness J, Ottavio A, Sinclair J, Hickie IB. Co-designing the Innowell Platform to deliver the right mental health care, first time to regional youth. Australian Journal of Rural Health (in press - March 2020).</Typography>
                  </li>
                </ul>
                <Typography type='heading' preset='3'>Conferences:</Typography>
                <ul>
                    <li><Typography type='paragraph' preset={2}>Rowe S, Sinclair J. Co-designing the Innowell Platform to deliver the right mental health care, first time to regional youth. Australian Rural &amp; Remote Mental Health Symposium, 2019: Adelaide, South Australia.</Typography></li>
                    <li><Typography type='paragraph' preset={2}>Rowe SC, Jackson T, Davenport T, Easton M, Melsness J, Ottavio A, Hickie I. Implementing technology-enabled solutions in regional youth mental health services: barriers, challenges, levers and opportunities. Society of Mental Health Research, 2019: Melbourne, Victoria.</Typography></li>
                </ul>
            
                <Typography type='heading' preset='2'>Older People's Trial</Typography>
                <Typography type='paragraph' preset='2'>Concluded December 2020</Typography>
                <Typography type='paragraph' preset={2}>Trial 10 of Project Synergy is being conducted in partnership with the University of Sydney's Brain and Mind Centre. In order to identify the content and configuration requirements of the Innowell Platform for older adults, appropriate groups/ clinics at the Brain and Mind Centre, such as the Healthy Brain Ageing (HBA) Research Group, will be engaged for the purposes of co-designing a prototype of the Platform.</Typography>
                <Typography type='paragraph' preset={2}>The primary aim of this research was to co-design and configure the Innowell Platform through co-design workshops and user testing with participants recruited through appropriate groups/ clinics at the Brain and Mind Centre (including partner organisations) to ensure the accessibility, engagement and appropriateness of the technology to best meet the needs of an older age group (50 years and older) and their supportive others (eg. family member, care-giver), as well as health professionals, service managers and administrators working with this population.</Typography>
            
                <Typography type='heading' preset='2'>The Butterfly Foundation</Typography>
                <Typography type='paragraph' preset='2'>Concluded April 2020</Typography>
                <Typography type='paragraph' preset={2}>Trial 7 of Project Synergy was conducted in partnership with <SafeLink to="https://butterfly.org.au/">The Butterfly Foundation</SafeLink>. The Butterfly Foundation's National Helpline is a free and confidential service which provides information, counselling and treatment referrals for eating disorders, disordered eating, body image and related issues via telephone, online chat or email.</Typography>
                <Typography type='paragraph' preset={2}>The Helpline provides information, support and guidance on treatment options as well as referral pathways on an as needs basis, and is delivered as a brief intervention with no ongoing therapeutic engagement with those who contact the service. Commonly, the Helpline is the first contact point for a person with concerns about their eating and body image and those that have not experienced any type of treatment to date.</Typography>
                <Typography type='paragraph' preset={2}>The primary aim of this research was to evaluate, prototype and configure a digital system for a non-traditional (online, phone, email) service providing support for eating disorders and body image issues through the use of co-design processes.</Typography>
                
                <Typography type='heading' preset='3'>Conferences:</Typography>
                <ul>
                    <li>
                      <Typography type='paragraph' preset={2}>Hambleton A, Ottavio A, LaMonica H, Healey N, Davenport T, Hickie I. Technology-enabled service models for quality improvements in Australian mental health care delivery. 2nd Asia Pacific Integrated Care Conference, 2019: Melbourne, Australia.</Typography>
                    </li>
                </ul>            

                <Typography type='heading' preset='2'>Kildare Road Medical Centre</Typography>
                <Typography type='paragraph' preset='2'>Concluded June 2020</Typography>
                <Typography type='paragraph' preset={2}>Trial 11 of Project Synergy was conducted in partnership with <SafeLink to="https://www.kildaremedical.com.au/">Kildare Road Medical Centre</SafeLink> and was focussed on supporting mental health care as it is delivered through Australian general practice. Kildare Road Medical Centre is committed to the provision of high quality care for its patients, by way of offering convenient on-site access to a wide range of medical services for comprehensive and coordinated care.</Typography>
                <Typography type='paragraph' preset={2}>The overarching aim of this research was to configure and implement the Innowell Platform to support Kildare Road Medical Centre's delivery of mental health care.</Typography>
        </Section>

        <Section theme='mint'>
          <Typography type='heading' preset='1'>Publications</Typography>
          <Typography type='paragraph' preset={2}>Below are a list of Innowell research publications (listed alphabetically by author):</Typography>
          <ul>
              <li><Typography type='paragraph' preset={2}>Buus N, Juel A, Haskelberg H, Frandsen H, Larsen JLS, River J, Andreasson K, Nordentoft M, Davenport T, Erlangsen A. User involvement in developing the MYPLAN mobile phone safety plan app for people in suicidal crisis: case study. JMIR Mental Health 2019; 6(4): e11965. DOI: doi: 10.2196/11965</Typography></li>
              <li><Typography type='paragraph' preset={2}>Carpenter JS, Iorfino F, Cross SP, Davenport TA, Hermens DF, Rohleder C, Crouse JJ, F Markus Leweke, Koethe D, Guastella AJ, Naismith SL, Scott J, Scott EM, Hickie IB. Combining clinical stage and pathophysiological mechanisms to understand illness trajectories in young people with emerging mood and psychotic syndromes. Medical Journal of Australia 2019; 211 Suppl 9: S12-S22. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Cheng VWS, Davenport T, Johnson D, Vella K, Hickie IB. Gamification in apps and technologies for improving mental health and well-being: systematic review. JMIR Mental Health 2019; 6(6): e13717. doi: 10.2196/13717</Typography></li>
              <li><Typography type='paragraph' preset={2}>Cross SP, Davenport TA, LaMonica HM, Hickie IB. Potential of real-time and integrated clinical data to drive continuous quality improvement in youth mental health services. Medical Journal of Australia 2019; 211 Suppl 7: S37-S39. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Cross SP, Davenport TA, Scott EM, Iorfino F, Sawrikar V, Hickie IB. A service delivery model to support highly personalised and measurement-based care in youth mental health. Medical Journal of Australia 2019; 211 Suppl 9: S42-S46. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Cross SP, Piper SE, Davenport TA, Milton AC, Iorfino F, Ricci CS, Ospina-Pinillos L, Whittle L, Hickie IB. Implementation study of a prototypic e-clinic being integrated into youth mental health services: staff experiences and reported service quality improvements. Medical Journal of Australia 2019; 211 Suppl 7: S30-S36. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Crouse JJ, Rohleder C, Carpenter JS, Iorfino F, Tickell AM, Cross SP, Davenport TA, Hermens DF, Guastella AJ, F Markus Leweke, Koethe D, Naismith SL, Scott EM, Hickie IB. A comprehensive assessment framework for youth mental health: guiding highly personalised and measurement-based care using multidimensional and objective measures. Medical Journal of Australia 2019; 211 Suppl 9: S23-S31. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport T, et al. Developing Medico-Legal and Ethical Guidelines for the use of health information technologies by Australian mental health service providers. (submitted to Journal of Participatory Medicine).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport TA, LaMonica HM, Whittle L, English A, Iorfino F, Cross S, Hickie IB. Validation of the InnoWell Platform: protocol for a clinical trial. JMIR Research Protocols 2019; 8(5): e13955. doi: 10.2196/13955</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport TA, Milton AC, Ospina-Pinillos L, Whittle L, Ricci CS, Burns JM, Hickie IB. Project Synergy research and development cycle: iterative processes of participatory design, user testing, implementation and feasibility testing. Medical Journal of Australia 2019; 211 Suppl 7: S8-S11. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport TA, Milton AC, Whittle L, McLoughlin LT, Ospina-Pinillos L, Mendoza J, Burns JM, Hickie IB. Co-designing, user testing and evaluating digital suicide prevention functionality. Medical Journal of Australia 2019; 211 Suppl 7: S24-S29. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport TA, Ospina-Pinillos L, Ricci CS, Milton AC, Burns JM, Hickie IB. Fit Uni Life to thrive: an online health and wellbeing prototype for young people. Medical Journal of Australia 2019; 211 Suppl 7: S12-S16. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Davenport TA, Whittle L, Ospina-Pinillos L, Milton AC, Burns JM, Hickie IB. Evaluating engagement with a prototypic online platform to improve the mental health and wellbeing of young people living in disadvantaged communities. Medical Journal of Australia 2019; 211 Suppl 7: S17-S23. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie I, Davenport T, LaMonica H, Iorfino F. Prioritising and adopting key national policies to promote world-leading digital mental health care. (submitted to Medical Journal of Australia).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB, Davenport TA, Burns JM. Overview of Project Synergy: building an online platform to deliver right care, first time. Medical Journal of Australia 2019; 211 Suppl 7: S4-S7. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB, Davenport TA, Burns JM. Project Synergy: co-designing technology-enabled solutions for Australian mental health services reform. Medical Journal of Australia 2019; 211 Suppl 7: S3. doi: 10.5694/mja2.50349</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB, Scott EM, Cross SP, Iorfino F, Davenport TA, Guastella AJ, Naismith SL, Carpenter JS, Rohleder C, Crouse JJ, Hermens DF. Right care, first time: a highly personalised and measurement-based care model to manage youth mental health. Medical Journal of Australia 2019; 211 Suppl 9: S3. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB. Moving beyond stepped care to staged care using a novel, technology-enabled care model for youth mental health. Med J Aust 2019; 211 (9). DOI: 10.5694/mja2.50379</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB. The “uberisation” of mental health care: a welcome global phenomenon? Med J Aust 2019; 211 (7). DOI: 10.5694/mja2.50342</Typography></li>
              <li><Typography type='paragraph' preset={2}>Hickie IB. The role of new technologies in monitoring the evolution of psychopathology and providing measurement-based care in young people. World Psychiatry 2020; 19(1):38-39. Doi: <SafeLink to="https://doi.org/10.1002/wps.20697">The role of new technologies in monitoring the evolution of psychopathology and providing measurement-based care in young people</SafeLink></Typography></li>
              <li><Typography type='paragraph' preset={2}>Iorfino F, Carpenter JS, Cross SP, Davenport TA, Hermens DF, Guastella AJ, Rohleder C, Crouse JJ, F Markus Leweke, Koethe D, Naismith SL, Scott EM, Hickie IB. Multidimensional outcomes in youth mental health care: what matters and why? Medical Journal of Australia 2019; 211 Suppl 9: S4-S11. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Iorfino F, Cross SP, Davenport T, Carpenter JS, Scott E, Shiran S, Hickie IB. A digital platform designed for youth mental health services to deliver personalized and measurement-based care. Frontiers in Psychiatry - Frontiers Research Foundation 2019; 10: 595. doi: <SafeLink to="https://doi.org/10.3389/fpsyt.2019.00595">A Digital Platform Designed for Youth Mental Health Services to Deliver Personalized and Measurement-Based Care</SafeLink></Typography></li>
              <li><Typography type='paragraph' preset={2}>Iorfino F, et al. NCPHN and Dynamic Simulation Modelling (submitted).</Typography></li>
              <li><Typography type='paragraph' preset={2}>LaMonica H, et al. Understanding technology preferences and requirements for health information technologies designed to improve mental health and maintain wellbeing for older persons: A participatory design study. (submitted to JMIR Aging)</Typography></li>
              <li><Typography type='paragraph' preset={2}>LaMonica HM, Davenport TA, Braunstein K, Ottavio A, Piper S, Martin C, Hickie IB, Cross S. Technology-enabled person-centered mental health services reform: strategy for implementation science. JMIR Mental Health 2019; 6(9): e14719. doi: 10.2196/14719</Typography></li>
              <li><Typography type='paragraph' preset={2}>LaMonica HM, Davenport TA, Burns J, Cross S, Hodson S, Veitch J, Hickie IB. Technology-enabled mental health service reform for Open Arms - Veterans and Families Counselling: participatory design study. JMIR Formative Research 2019; 3(3):e13662. doi: 10.2196/13662</Typography></li>
              <li><Typography type='paragraph' preset={2}>LaMonica HM, Milton A, Braunstein K, Rowe SC, Ottavio A, Jackson T, Easton MA, Hambleton A, Hickie IH, Davenport TA. Health information technology-enabled solutions for Australian mental health services reform: impact evaluation. JMIR 2020 (accepted).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Milton AC, Hambleton A, Dowling M, Roberts A, Hickie IB, Davenport TA. Co-designing technology-enabled reform in a non-traditional mental health service for eating disorders. JMIR 2020 (accepted).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Ospina-Pinillos L, Davenport T, Mendoza Diaz A, Navarro-Mancilla A, Scott EM, Hickie IB. Using participatory design methodologies to co-design and culturally adapt the Spanish version of the Mental Health eClinic: qualitative study. Journal of Medical Internet Research 2019; 21(8): e14127. doi: 10.2196/14127</Typography></li>
              <li><Typography type='paragraph' preset={2}>Ospina-Pinillos L, Davenport TA, Andres Navarro-Mancilla A, Wan Sze Cheng V, Camilo Cardozo Alarcón A, Rangel AM, Rueda-Jaimes GE, Restrepo CG, Hickie IB. Involving end users in adapting the Spanish version of a web-based mental health clinic for young people in Colombia: an exploratory study using participatory design methodologies. JIMR Mental Health 2020; 7(2): e15914. doi: 10.2196/15914</Typography></li>
              <li><Typography type='paragraph' preset={2}>Roberts A, et al. Evaluating the quality and safety of health-related apps and e-tools: adapting the Mobile App Rating Scale and developing a quality assurance protocol. Internet Interventions (submitted to Internet Interventions).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Rohleder C, Crouse JJ, Carpenter JS, Iorfino F, Cross SP, Davenport TA, Hermens DF, Guastella AJ, F Markus Leweke, Koethe D, Naismith SL, Scott EM, Hickie IB. Personalising care options in youth mental health: using multidimensional assessment, clinical stage, pathophysiological mechanisms, and individual illness trajectories to guide treatment selection. Medical Journal of Australia 2019; 211 Suppl 9: S32-S41. doi: 10.5694/mja2.50383</Typography></li>
              <li><Typography type='paragraph' preset={2}>Rowe SC, Davenport TA, Easton MA, Jackson TA, Melsness J, Ottavio A, Sinclair J, Hickie IB. Co-designing the InnoWell Platform to deliver the right mental health care first time to regional youth. Australian Journal of Rural Health 2020. doi: <SafeLink to="https://doi.org/10.1111/ajr.12617">Co-designing the InnoWell Platform to deliver the right mental health care first time to regional youth</SafeLink></Typography></li>
              <li><Typography type='paragraph' preset={2}>Sawrikar V, Stewart E, LaMonica H, Iorfino F, Davenport T, Cross S, Scott E, Naismith S, Mowszowski L, Guastella A, Hickie IB. Using staged care to get people with common affective disorders right care first time. (submitted to Psychiatric Services).</Typography></li>
              <li><Typography type='paragraph' preset={2}>Tickell AM, Scott EM, Davenport T, Iorfino F, Ospina-Pinillos L, White D, Harel K, Parker L, Hickie IB, Hermens DF. Developing neurocognitive standard clinical care: a study of young adult inpatients. Psychiatry Research 2019; 276: 232-238. doi: 10.1016/j.psychres.2019.05.003</Typography></li>
              <li><Typography type='paragraph' preset={2}>Tickell AM, Scott EM, Davenport T, Iorfino F, Ospina-Pinillos L, Harel K, Parker L, Hickie IB, Hermens DF. Neurocognitive clusters: a pilot study of young people with affective disorders in an inpatient facility. Journal of Affective Disorders 2019; 242: 80-86. doi: <SafeLink to="https://doi.org/10.1016/j.jad.2018.08.045">Neurocognitive clusters: A pilot study of young people with affective disorders in an inpatient facility</SafeLink></Typography></li>
          </ul>
        </Section>
      </Page>
    </>
  )
};

export default ProjectSynergy;